/* You can add global styles to this file, and also import other style files */
@import 'material-icons/iconfont/material-icons.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~ngx-toastr/toastr';
agm-map {
  height: 95%;
}
:root{
  --primary-color: #007AF3;
}
body{
  background: #F2F2F2;
  margin: 0;
}

a {
  color: #444444;
}

ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; overflow-x: hidden; }


input.form-control,input.form-control:focus{
  background-color: #636b7b;
  color: #fff;
  border: 1px solid #636b7b;
}

input.form-control:focus{
  box-shadow: none;
}

button[type=submit].btn{
  border-radius: 20px;
  background-color: #04c582;
  color: #fff;
}
div.form-group label:not(.text-danger){
  color: #545d6a;
  font-weight: 700;
}

div.form-group.required>label:first-child:after{
  content:'*';
  color:red;
  padding-left: 5px;
}

#toast-container > div {
  opacity:1;
}
.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
  top:30%;
}

.wrapped-div {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #2e3137;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
}

/* Tabs */
.tab-header{
  text-align: center;
}

.tab-header h2.active {
  color: #fff;
  border-bottom: 4px solid #fff;
}

.tab-header h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  display:inline-block;
  padding: 30px 40px 10px 40px;
  cursor: pointer;
  color: #545d6a;
  border-bottom: 2px solid #545d6a;
}

.tab-header h2:focus {
    outline: none;
}

form{
  margin: 0px 16px;
}

div.form-group input.invalid{
  border: 1px solid #dc3545;
}
div.form-group label:first-child{
  text-transform: uppercase;
  font-size: 0.9rem;
}



html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
